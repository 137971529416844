import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/tablestandings.css';

const FootballTables = () => {
    const [englishPremierLeague, setEnglishPremierLeague] = useState([]);
    const [englishChampionship, setEnglishChampionship] = useState([]);
    const [englishLeagueOne, setEnglishLeagueOne] = useState([]);
    const [englishLeagueTwo, setEnglishLeagueTwo] = useState([]);
    const [englishNationalLeague, setEnglishNationalLeague] = useState([]);

    function getRowClass(index, competition) {
        let position = ++index;

        if ((competition == 'PremierLeague' && position >= 1 && position <= 4) ||
            (competition == 'Championship' && position >= 1 && position <= 2) ||
            (competition == 'LeagueOne' && position >= 1 && position <= 2) ||
            (competition == 'LeagueTwo' && position >= 1 & position <= 3) ||
            (competition == 'NationalLeague' && position == 1)) {
            return "automatic-promotion";
        }

        if (
            (competition == 'Championship' && position >= 2 && position <= 6) ||
            (competition == 'LeagueOne' && position >= 2 && position <= 6) ||
            (competition == 'LeagueTwo' && position >= 2 & position <= 7) ||
            (competition == 'NationalLeague' && position >= 2 & position <= 7)) {
            return "playoffs";
        }

        if ((competition == 'PremierLeague' && position >= 18) ||
            (competition == 'Championship' && position >= 22) ||
            (competition == 'LeagueOne' && position >= 21) ||
            (competition == 'LeagueTwo' && position >= 23) ||
            (competition == 'NationalLeague' && position >= 21)) {
            return "relegation";
        }
    }

    useEffect(() => {
        let apiUrl = 'https://dotnet-function-apps.azurewebsites.net/api/Football_TableStandingsHttpTrigger?division=EnglishPremierLeague';
        axios.get(apiUrl).then((result) => {            
            var tableData = result.data.Data;
            var tableRows = tableData.slice(1);
            setEnglishPremierLeague(tableRows);
        });

        apiUrl = 'https://dotnet-function-apps.azurewebsites.net/api/Football_TableStandingsHttpTrigger?division=EnglishChampionship';
        axios.get(apiUrl).then((result) => {
            var tableData = result.data.Data;
            var tableRows = tableData.slice(1);
            setEnglishChampionship(tableRows);
        });

        apiUrl = 'https://dotnet-function-apps.azurewebsites.net/api/Football_TableStandingsHttpTrigger?division=EnglishLeagueOne';
        axios.get(apiUrl).then((result) => {
            var tableData = result.data.Data;
            var tableRows = tableData.slice(1);
            setEnglishLeagueOne(tableRows);
        });

        apiUrl = 'https://dotnet-function-apps.azurewebsites.net/api/Football_TableStandingsHttpTrigger?division=EnglishLeagueTwo';
        axios.get(apiUrl).then((result) => {
            var tableData = result.data.Data;
            var tableRows = tableData.slice(1);
            setEnglishLeagueTwo(tableRows);
        });

        apiUrl = 'https://dotnet-function-apps.azurewebsites.net/api/Football_TableStandingsHttpTrigger?division=EnglishNationalLeague';
        axios.get(apiUrl).then((result) => {
            var tableData = result.data.Data;
            var tableRows = tableData.slice(1);
            setEnglishNationalLeague(tableRows);
        });
    }, []);


    return (
        <React.Fragment>
            <article id="football-tables">
                <div className='row'>
                    <div>
                        <h6><span>English Premier League</span></h6>
                        <table className="alt football-standings">
                            <thead>
                                <tr>
                                    <th>Pos</th>
                                    <th>Team</th>
                                    <th>P</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                    <th>GF</th>
                                    <th>GA</th>
                                    <th>GD</th>
                                    <th>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {englishPremierLeague && englishPremierLeague.map((item, index) =>  (                                    
                                    <tr key={`tr${index}`} className={getRowClass(index, 'PremierLeague')}>
                                        {item.map((_, y) => (                                            
                                            <td key={`td-${item[1]}-${y}`}>{item[y]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h6><span>English Championship</span></h6>
                        <table className="alt football-standings">
                            <thead>
                                <tr>
                                    <th>Pos</th>
                                    <th>Team</th>
                                    <th>P</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                    <th>GF</th>
                                    <th>GA</th>
                                    <th>GD</th>
                                    <th>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {englishChampionship && englishChampionship.map((item, index) => (
                                    <tr key={`tr${index}`} className={getRowClass(index, 'Championship')}>
                                        {item.map((_, y) => (
                                            <td key={`td-${item[1]}-${y}`}>{item[y]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h6><span>English League One</span></h6>
                        <table className="alt football-standings">
                            <thead>
                                <tr>
                                    <th>Pos</th>
                                    <th>Team</th>
                                    <th>P</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                    <th>GF</th>
                                    <th>GA</th>
                                    <th>GD</th>
                                    <th>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {englishLeagueOne && englishLeagueOne.map((item, index) => (
                                    <tr key={`tr${index}`} className={getRowClass(index, 'LeagueOne')}>
                                        {item.map((_, y) => (
                                            <td key={`td-${item[1]}-${y}`}>{item[y]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h6><span>English League Two</span></h6>
                        <table className="alt football-standings">
                            <thead>
                                <tr>
                                    <th>Pos</th>
                                    <th>Team</th>
                                    <th>P</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                    <th>GF</th>
                                    <th>GA</th>
                                    <th>GD</th>
                                    <th>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {englishLeagueTwo && englishLeagueTwo.map((item, index) => (
                                    <tr key={`tr${index}`} className={getRowClass(index, 'LeagueTwo')}>
                                        {item.map((_, y) => (
                                            <td key={`td-${item[1]}-${y}`}>{item[y]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h6><span>English National League</span></h6>
                        <table className="alt football-standings">
                            <thead>
                                <tr>
                                    <th>Pos</th>
                                    <th>Team</th>
                                    <th>P</th>
                                    <th>W</th>
                                    <th>D</th>
                                    <th>L</th>
                                    <th>GF</th>
                                    <th>GA</th>
                                    <th>GD</th>
                                    <th>Pts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {englishNationalLeague && englishNationalLeague.map((item, index) => (
                                    <tr key={`tr${index}`} className={getRowClass(index, 'NationalLeague')}>
                                        {item.map((_, y) => (
                                            <td key={`td-${item[1]}-${y}`}>{item[y]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>
        </React.Fragment>
    );
}

export default FootballTables;